import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="contact-info">
                <p>Email</p>
                <p><a href="mailto:dksfugimoto@gmail.com?subject=Contato&body=Olá, gostaria de mais informações sobre seus serviços."
                    target="_blank" rel="noopener noreferrer">dksfugimoto@gmail.com</a></p>
            </div>
            <div className="copyright">
                <p>© 2024 By Daniel Fugimoto</p>
            </div>
            <div className="github">
                <p>GitHub</p>
                <p><a href="https://github.com/DanielFugimoto" target="_blank" rel="noopener noreferrer">https://github.com/DanielFugimoto</a></p>
            </div>
        </footer>
    );
}

export default Footer;
