import React, { useEffect } from 'react';
import '../css/about.css';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
        });
    }, []);

    return <section>
        <div className='estudos' data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
            <h2>Formação Acadêmica</h2>
            <ul>
                <li>
                    <p><strong>2023 - Cursando:</strong> Análise e Desenvolvimento de Sistemas - Unicesumar</p>
                </li>
                <li>
                    <p><strong>2023 - 2023:</strong> Pós-Graduação em Desenvolvimento de Sistemas em C# - Facuminas</p>
                </li>
                <li>
                    <p><strong>2023 - Cursando:</strong> Pós-Graduação em Inteligência Artificial - Facuminas</p>
                </li>
                <li>
                    <p><strong>2023 - Cursando:</strong> Pós-Graduação em Desenvolvimento de Jogos Digitais - Facuminas</p>
                </li>
                <li>
                    <p><strong>2016 - 2022:</strong> Licenciatura em Educação Física - Universidade Estadual de Maringá</p>
                </li>
            </ul>
        </div>

        <hr />

        <div className='estudos' data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
            <h2>Experiências Profissionais</h2>
            <ul>
                <li>
                    <p><strong>2024 - Presente</strong>Professor do Curso Técnico de Desenvolvimento de Sistemas</p>
                    <ul>
                        <li>
                            Programação Front End
                            <li>
                                Lecionei conteúdos como: HTML, CSS, JavaScript e React.
                            </li>
                        </li>
                        <br />
                        <li>
                            Programação Back End
                            <li>
                                Lecionei conteúdos como: NodeJS, POO.
                            </li>
                        </li>
                        <br />
                        <li>
                            Programação Mobile
                            <li>
                                Lecionei conteúdos como: React Native.
                            </li>
                        </li>
                        <br />
                        <li>
                            Atividades extras desenvolvidas:
                            <li>
                                Orientação no desenvolvimento de projetos dos alunos
                            </li>
                        </li>
                    </ul>
                </li>
                <br />
                <li>
                    <p><strong>2023 - Presente</strong>Professor de Pensamento Computacional</p>
                    <ul>
                        <li>
                            Pensamento Computacional
                            <li>
                                Lecionei conteúdos como: Raciocínio Lógico, Lógica de Programação e Desenvolvimento de jogos.
                            </li>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <hr />

        <div className='estudos' data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">
            <h2>Cursos</h2>
            <ul>
                <li>
                    <p><strong>2022:</strong> DB1 Start - DB1</p>
                </li>
                <li>
                    <p><strong>2021:</strong> Algoritmos e Lógica de Programação - Udemy</p>
                </li>
                <li>
                    <p><strong>2022 - 2023:</strong> Cursos da Alura - <a href=''>Certificações</a></p>
                </li>
            </ul>
        </div>

        <button><Link to="/">← Voltar </Link></button>

    </section>
};

export default About;