import React from 'react';
import { Link } from 'react-router-dom';
import '../css/header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <div className="circle"></div>
        <p><a href='/' className='name'>Daniel Fugimoto</a></p>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">Resumo</Link></li>
          <li><Link to="/projetos">Projetos</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;