import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import SobreMim from './pages/sobre';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/footer';
import Projetos from './pages/projetos';

const App = () => {
  return (
    <Router>
      <Header />
      <hr />
      <Routes>
        <Route path="/" element={<SobreMim />} />
        <Route path='/projetos' element={<Projetos />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
