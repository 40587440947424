import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/projetos.css';

const Projetos = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
        });
    }, []);

    return (

        <section>
            <div data-aos="fade-up-right" data-aos-anchor-placement="top-center">
                <h2>Bot Discord</h2>

                <h3>Informações do Projeto:</h3>
                <div class="project">
                    <ul>
                        <li><strong>Curso:</strong> DB1 Start</li>
                        <li><strong>Data do Projeto:</strong> 05 de Outubro de 2022</li>
                        <li><strong>Tecnologias usadas:</strong> Python</li>
                        <li><strong>Link do projeto:</strong><a href="https://github.com/DanielFugimoto/botdiscord" target="_blank" rel="noopener noreferrer"> GitHub</a></li>
                    </ul>
                    <p>Esse Bot foi o projeto final do curso DB1 Start que eu fiz no ano de 2022, ele foi feito em Python
                        e tinha algumas funcionalidades como sortear um dado aleatório de 20 lados, sortear uma moeda para
                        tirar cara ou coroa, encaminhar um link com as regras do servidor (que poderia ser alterado para ele
                        dizer as regras diretamente). Foi o primeiro projeto em que desenvolvi sozinho, foi bastante desafiador,
                        mas eu gostei muito do resultado de como ele ficou.</p>
                </div>
            </div>

            <hr />

            <div data-aos="fade-up-left" data-aos-anchor-placement="top-center">
                <h2>Projeto FullStack</h2>

                <h3>Informações do Projeto:</h3>
                <div class="project">
                    <ul>
                        <li><strong>Curso:</strong> Projeto Próprio</li>
                        <li><strong>Data do Projeto:</strong> 06 de Junho de 2024</li>
                        <li><strong>Tecnologias usadas:</strong> NodeJS, ReactJS e TypeScript</li>
                        <li><strong>Link do projeto:</strong><a href="#" target="_blank" rel="noopener noreferrer"> GitHub</a></li>
                    </ul>
                    <p>Este projeto foi desenvolvido para estudar a tecnologia JavaScript em suas diversas aplicações,
                        tanto no back-end com Node.js quanto no front-end com TypeScript. O site permite o cadastro de pessoas,
                        incluindo nome, e-mail e o computador que será utilizado. Após o cadastro, é possível iniciar, reiniciar
                        ou zerar um timer de uma hora para cada usuário. Além disso, o site oferece a funcionalidade de excluir usuários cadastrados.</p>
                </div>
            </div>

            <hr />

            <div data-aos="fade-up-right" data-aos-anchor-placement="top-center">
                <h2>Sorteador de Times</h2>

                <h3>Informações do Projeto:</h3>
                <div class="project">
                    <ul>
                        <li><strong>Curso:</strong> Projeto Próprio</li>
                        <li><strong>Data do Projeto:</strong> 09 de Maio de 2024</li>
                        <li><strong>Tecnologias usadas:</strong> JavaScript</li>
                        <li><strong>Link do projeto:</strong><a href="#" target="_blank" rel="noopener noreferrer"> GitHub</a></li>
                    </ul>
                    <p>Esse site para realizar o sorteio de times, eu tive a ideia de fazer ele após uma conversa com
                        amigos onde nós jogamos bola toda semana e para tentar facilitar a divisão dos times foi pensado
                        esse sorteador, onde nele colocamos o nome de cada jogador e damos uma nota de 0-10 para cada
                        pessoa para depois separar os times de forma que fiquem equilibrados. O site foi feito em HTML, CSS e JS.</p>
                </div>
            </div>

            <hr />

            <div data-aos="fade-up-left" data-aos-anchor-placement="top-center">
                <h2>Jogo da Velha</h2>

                <h3>Informações do Projeto:</h3>
                <div class="project">
                    <ul>
                        <li><strong>Curso:</strong> Projeto Próprio</li>
                        <li><strong>Data do Projeto:</strong> 04 de Janeiro de 2024</li>
                        <li><strong>Tecnologias usadas:</strong> JavaScript</li>
                        <li><strong>Link do projeto:</strong><a href="#" target="_blank" rel="noopener noreferrer"> GitHub</a></li>
                    </ul>
                    <p>Jogo criado para treinar as habilidades em HTML, CSS e JavaScript. Jogo simples para dois jogadores sem a implementação de jogar contra alguma IA.</p>
                </div>
            </div>

            <button><Link to="/">← Voltar </Link></button>

        </section>

    );
}

export default Projetos;
