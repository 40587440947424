import React from 'react';
import '../css/sobre.css';

function SobreMim() {
  return (
    <section className="sobre-mim">
      <div className="container">
        <img src={`${process.env.PUBLIC_URL}/eu.jpg`} alt="Minha imagem" className="profile-pic" />
        <div className="texto-sobre-mim">
          <h1 className='name-sobre'>Daniel Fugimoto</h1>
          <h2 className='sobre-sobre'>Sobre mim</h2>
          <p className='p-sobre'>
            É um prazer me apresentar. Meu nome é Daniel, sou graduado em Educação Física pela Universidade Estadual de Maringá.
            No entanto, decidi realizar uma transição de carreira e desde 2021, venho dedicando-me aos estudos de programação.
            Com o objetivo de aprimorar e aprofundar meus conhecimentos, iniciei o curso de Análise e Desenvolvimento de Sistemas
            em 2023. Estou entusiasmado em ampliar minhas habilidades e desenvolver ainda mais meu potencial nessa jornada.
          </p>
        </div>
      </div>
    </section>
  );
}

export default SobreMim;
