import React from 'react';
import { Link } from 'react-router-dom';
import '../css/contact.css';

const Contact = () => {
  return <section className='sec-con'>
    <div className='contato-full'>
      <h1>Redes Sociais</h1>
      <br />

      <div className='contato'>
        <h3><strong>Linkedin: </strong><a href='https://www.linkedin.com/in/daniel-fugimoto/' target="_blank" rel="noopener noreferrer">Daniel Fugimoto</a></h3>
      </div>
      <div className='contato'>
        <h3><strong>GitHub: </strong><a href='https://github.com/DanielFugimoto' target="_blank" rel="noopener noreferrer">Daniel Fugimoto</a></h3>
      </div>
    </div>
    <button><Link to="/">← Voltar </Link></button>
  </section>
};

export default Contact;


